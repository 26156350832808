<template>
	<div class="home-container">
		<div class="overlay">
			<h2>欢迎来到原神农业大学</h2>
			<p>快来加入开放世界的冒险吧！</p>
			<el-row>
			      <el-button type="primary" plain @click="openLink('https://www.imau.edu.cn/')">了解更多</el-button>
			</el-row>
		</div>

	</div>
</template>


<script>
	export default {
		name: 'Home',
		methods: {
		  openLink(url) {
		    const win = window.open('', '_blank');
		    win.opener = null;
		    win.location.href = url;
		  }
		}
	};
</script>

<style scoped>
	.home-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		background: url('https://image.33mc.cn/luntan/2024/yuanShenTwoYear2560-1440small.jpg') no-repeat center center/cover;
		color: white;
		text-align: center;
	}

	.overlay {
		background-color: rgba(0, 0, 0, 0.5);
		padding: 2rem;
		border-radius: 10px;
	}

	h2 {
		font-size: 2.5rem;
		margin-bottom: 1rem;
	}

	p {
		font-size: 1.25rem;
		margin-bottom: 2rem;
	}

</style>