<template>
	<footer class="footer-transparent">
		<div class="footer-content">
			<p>&copy; 2024 原神农业大学. 保留所有权利.</p>
		</div>
	</footer>
</template>
<script>
	export default {
		name: 'TranslucentFooter'
	};
</script>
<style scoped>
	.footer-transparent {
		background-color: rgba(72, 72, 72, 0.7);
		/* 半透明绿色背景 */
		color: rgba(255, 255, 255, 0.6);
		padding: 5px;
		text-align: center;
		position: fixed;
		width: 100%;
		bottom: 0;
		box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
		backdrop-filter: blur(5px);
		/* 添加模糊效果以增强半透明美感 */
		z-index: 100;
		/* 确保 footer 在页面最上层 */
	}

	.footer-content {
		position: relative;
		z-index: 1;
		/* 确保文字在模糊效果之上 */
	}

	.footer-transparent p {
		margin: 0;
		font-size: 1rem;
		line-height: 1.5;
		font-family: 'Roboto', sans-serif;
	}
</style>