<template>
	<header class="header-container">
		<h1>原神农业大学</h1>
	</header>
</template>

<script>
	export default {
		name: 'Header'
	};
</script>

<style scoped>
	.header-container {
		background: rgba(76, 175, 80, 0.8);
		color: white;
		padding: 1rem;
		text-align: center;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
		position: sticky;
		top: 0;
		z-index: 1000;
	}

	/* 兼容性处理 */
	@supports ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
		.header-container {
			-webkit-backdrop-filter: blur(10px);
			/* 添加 Safari 的支持 */
			backdrop-filter: blur(10px);
		}
	}


	h1 {
		font-size: 25px;
		margin: 0;
		font-family: 'Arial', sans-serif;
		text-transform: uppercase;
		letter-spacing: 2px;
	}
</style>