<template>
  <div id="app">
    <Header />
    <Navbar />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';
import { useMeta } from 'vue-meta';

export default {
  name: 'App',
  setup() {
    useMeta({
      title: '原神农业大学',
      meta: [
        { name: 'description', content: '内蒙古农业大学，国家一流原神大学，点击进入学校官网' },
      ],
    });
  },
  components: {
    Header,
    Navbar,
    Footer
  }
};
</script>

<style>
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

router-view {
  flex: 1;
}
</style>
