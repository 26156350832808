<template>
	<nav class="navbar navbar-elegance">
		<div class="navbar-items">
			<router-link to="/" class="nav-link nav-link-luminescent">首页</router-link>
			<div class="navbar-divider"></div>
			<router-link to="/about" class="nav-link nav-link-luminescent">我们的故事</router-link>
			<div class="navbar-divider"></div>
			<router-link to="/aboutys" class="nav-link nav-link-luminescent">学院简介</router-link>
		</div>
	</nav>
</template>

<script>
	export default {
		name: 'NavbarExquisite'
	};
</script>

<style scoped>
	.navbar-elegance {
		background-color: rgba(72, 72, 72, 0.7);
		color: rgba(255, 255, 255, 0.6);
		padding: 5px;
		text-align: center;
		width: 100%;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		backdrop-filter: blur(5px);
		z-index: 100;
	}

	.navbar-items {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.nav-link-luminescent {
		color: rgba(255, 255, 255, 0.65);
		margin: 0 1.5rem;
		text-decoration: none;
		font-size: 1.4rem;
		transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	.nav-link-luminescent:hover {
		color: #ffeb3b;
		transform: scale(1.1) translateY(-3px);
		text-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
	}

	.navbar-divider {
		width: 1px;
		height: 20px;
		background-color: rgba(255, 255, 255, 0.3);
		margin: 0 20px;
	}
</style>