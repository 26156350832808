import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import AboutYsVue from './views/AboutYs.vue';

Vue.use(Router)

export default new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [{
			path: '/',
			name: 'home',
			component: Home
		},
		{
			path: '/about',
			name: 'about',
			component: () => import('./views/About.vue')
		}, {
			path: '/aboutys',
			name: 'AboutYsVue',
			component: AboutYsVue
		},
	]
})